import TextLoop from "react-text-loop"
import React from 'react'


function RotatingText() {


        return (
            
                <TextLoop mask={true} springConfig={{ stiffness: 70, damping: 31 }} interval={4000}  adjustingSpeed={500} children={["start a business.", "build a side hustle.", "grow a team."]} />
            
        );
        }

export default RotatingText;