import React from 'react';
import '../../App.css';
import ComingSoon from '../ComingSoon';
import Footer from '../Footer';
import '../Home.css';

function Home () {
    return (
        <>
            <ComingSoon />
            <Footer />
        </>
    )
}

export default Home;