import React from 'react'
import './ComingSoon.css'
import ContactForm from './ContactForm'
import logo from '../images/logo.png'
import RotatingText from './RotatingText'

function ComingSoon() {
    return (
        <div className='comingsoon-container'>
            <div className='comingsoon-hold-contents'>
            <div className='comingsoon-header'>
                <img src={logo} style={{ width: 200, height:62 ,  padding:20}} alt="Beyond Business Education"/>
            </div>
            <div className='info-container'>
                <h3>Free resources that teach </h3>
                <h3>you how to <RotatingText /></h3>  
                
            </div>           
            <div className='comingsoon-optin'>
                <h4>Coming Soon</h4>
                <ContactForm />
            </div>
        </div> 
        </div>
    )
}

export default ComingSoon;
