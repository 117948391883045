import React from 'react'
import './Footer.css'

function Footer() {
    return (
        <div className="footer-container">
        <p>
        <a target="_blank" without rel="noopener noreferrer" href="https://app.termly.io/document/privacy-policy/7c0d0da6-6ed8-4379-b38f-0d39ce3ed7cd">Privacy Policy</a>{"  "}  | {"  "}
        <a target="_blank" without rel="noopener noreferrer" href="https://app.termly.io/document/cookie-policy/84d6fc2b-a1e6-48d3-b5bb-8880f1ef0cec">Cookie Policy</a>{"  "} |  {"  "}    
        <a target="_blank" without rel="noopener noreferrer" href="https://app.termly.io/document/terms-of-use-for-website/2a562ea0-e98f-4cd0-82b1-205a17be8236">Terms & Conditions</a>   
        </p>
        </div>
    )
}

export default Footer;
